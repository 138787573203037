import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=c38729a2&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=js&"
export * from "./Menu.vue?vue&type=script&lang=js&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=c38729a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c38729a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VIcon,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VMenu,VNavigationDrawer,VTooltip})
