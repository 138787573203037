var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3"},[_c('v-row',{staticClass:"mt-5 ml-5 mr-5 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-toolbar-title',{staticClass:"text-h6 mt-4"},[_vm._v("Setting Menu Detail : ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"color":"#254e58","outlined":"","width":"120px","dark":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Back")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"#254e58","dark":"","disabled":!_vm.authorize_edit}},'v-btn',attrs,false),on),[_vm._v(" Save Data ")])]}}]),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to save this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeSaveDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.smd_view",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.smd_view,"color":"#254e58"},on:{"change":function($event){return _vm.setSwitchesForItem(item, 1)}}})]}},{key:"item.smd_add",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.smd_add,"color":"#254e58"},on:{"change":function($event){return _vm.setSwitchesForItem(item, 2)}}})]}},{key:"item.smd_edit",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.smd_edit,"color":"#254e58"},on:{"change":function($event){return _vm.setSwitchesForItem(item, 3)}}})]}},{key:"item.smd_del",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.smd_del,"color":"#254e58"},on:{"change":function($event){return _vm.setSwitchesForItem(item, 4)}}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#254e58"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }