var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3"},[_c('v-row',{staticClass:"mt-5 ml-5 mr-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-toolbar-title',{staticClass:"text-h6 mt-4"},[_vm._v("Production Status Report : ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"label":"Work order","hide-details":"","outlined":"","dense":"","items":_vm.workOrders,"item-text":"doc_running_no","item-value":"doc_running_no","clearable":""},model:{value:(_vm.itemsearch.doc_running_no),callback:function ($$v) {_vm.$set(_vm.itemsearch, "doc_running_no", $$v)},expression:"itemsearch.doc_running_no"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"label":"Work Center","hide-details":"","outlined":"","dense":"","items":_vm.workCenters,"item-text":_vm.getwork_center_name,"item-value":"id","clearable":""},model:{value:(_vm.itemsearch.work_center_id),callback:function ($$v) {_vm.$set(_vm.itemsearch, "work_center_id", $$v)},expression:"itemsearch.work_center_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"label":"Machine","hide-details":"","outlined":"","dense":"","items":_vm.machines,"item-text":_vm.getmachine_name,"item-value":"id","clearable":""},model:{value:(_vm.itemsearch.machine_id),callback:function ($$v) {_vm.$set(_vm.itemsearch, "machine_id", $$v)},expression:"itemsearch.machine_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","justify":"center"}},[_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"#1f51ff","dark":""},on:{"click":_vm.SearchData}},[_vm._v(" Search ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsAlldata,"search":_vm.search,"footer-props":{
       showFirstLastPage: true,
       firstIcon: 'mdi-arrow-collapse-left',
       lastIcon: 'mdi-arrow-collapse-right',
       prevIcon: 'mdi-minus',
       nextIcon: 'mdi-plus',
     },"sort-by":"fullname"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"flex text-center"},[_c('h4',[_vm._v("Plan")])])],1)]},proxy:true},{key:"item.setup_time",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.setup_time).toFixed(2))+" ")]}},{key:"item.time_process_by_opn",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.time_process_by_opn).toFixed(2))+" ")]}},{key:"item.real_qty_order_scrap_by_opn",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.order_qty).toFixed(2))+" ")]}},{key:"item.defect_qty",fn:function(ref){
     var item = ref.item;
return [_vm._v(" 0 ")]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsAlldata,"search":_vm.search,"footer-props":{
       showFirstLastPage: true,
       firstIcon: 'mdi-arrow-collapse-left',
       lastIcon: 'mdi-arrow-collapse-right',
       prevIcon: 'mdi-minus',
       nextIcon: 'mdi-plus',
     },"sort-by":"fullname"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"flex text-center"},[_c('h4',[_vm._v("Actual")])])],1)]},proxy:true},{key:"item.setup_time",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.tbl_routing.setup_time).toFixed(2))+" ")]}},{key:"item.time_process_by_opn",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.time_process_by_opn).toFixed(2))+" ")]}},{key:"item.real_qty_order_scrap_by_opn",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.real_qty_order_scrap_by_opn).toFixed(2))+" ")]}},{key:"item.defect_qty",fn:function(ref){
     var item = ref.item;
return [_vm._v(" "+_vm._s(item.real_qty_order_scrap_by_opn * ((item.tbl_routing.scrap?item.tbl_routing.scrap:0)/100))+" ")]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsAlldata,"search":_vm.search,"footer-props":{
       showFirstLastPage: true,
       firstIcon: 'mdi-arrow-collapse-left',
       lastIcon: 'mdi-arrow-collapse-right',
       prevIcon: 'mdi-minus',
       nextIcon: 'mdi-plus',
     },"sort-by":"fullname"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"flex text-center"},[_c('h4',[_vm._v("Different")])])],1)]},proxy:true},{key:"item.setup_time",fn:function(ref){
     var item = ref.item;
return [(Number(item.tbl_routing.setup_time - item.setup_time).toFixed(2) < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(Number(item.tbl_routing.setup_time - item.setup_time).toFixed(2)))]):_c('span',[_vm._v(" "+_vm._s(Number(item.tbl_routing.setup_time - item.setup_time).toFixed(2)))])]}},{key:"item.time_process_by_opn",fn:function(ref){
     var item = ref.item;
return [(Number(item.time_process_by_opn - item.time_process_by_opn).toFixed(2) < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(Number(item.time_process_by_opn - item.time_process_by_opn).toFixed(2)))]):_c('span',[_vm._v(" "+_vm._s(Number(item.time_process_by_opn - item.time_process_by_opn).toFixed(2)))])]}},{key:"item.real_qty_order_scrap_by_opn",fn:function(ref){
     var item = ref.item;
return [(Number(item.real_qty_order_scrap_by_opn - item.order_qty) < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(Number(item.real_qty_order_scrap_by_opn - item.order_qty)))]):_c('span',[_vm._v(" "+_vm._s(Number(item.real_qty_order_scrap_by_opn - item.order_qty)))])]}},{key:"item.defect_qty",fn:function(ref){
     var item = ref.item;
return [(Number(0 - item.real_qty_order_scrap_by_opn * ((item.tbl_routing.scrap?item.tbl_routing.scrap:0)/100)) < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(Number(0 - item.real_qty_order_scrap_by_opn * ((item.tbl_routing.scrap?item.tbl_routing.scrap:0)/100))))]):_c('span',[_vm._v(" "+_vm._s(Number(0 - item.real_qty_order_scrap_by_opn * ((item.tbl_routing.scrap?item.tbl_routing.scrap:0)/100))))])]}}])})],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.confirmDeleteDlg),callback:function ($$v) {_vm.confirmDeleteDlg=$$v},expression:"confirmDeleteDlg"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Confirm Delete ")]),_c('v-card-text',{staticClass:"body"},[_vm._v(" Are you sure to delete this timecard? You cannot restore it after clicking confirm. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.confirmDeleteDlg = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }