<template>
  <h1>This is an about page</h1>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      message: "Welcome to Vue.js!"
    };
  },
  mounted() {
    this.$hideLoader();
  }
}
</script>

<style>

</style>>
