var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3"},[_c('v-row',{staticClass:"mt-5 ml-5 mr-5"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Defect Cause : ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"sort-by":"fullname","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"#254E58","dark":"","disabled":!_vm.authorize_add},on:{"click":function($event){return _vm.$router.push("/defect-cause-add")}}},[_vm._v(" Add Defect Cause ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{staticClass:"mr-2",style:({
            backgroundColor: '#E1F5FE',
            color: '#2196F3',
          }),attrs:{"small":""},on:{"click":function($event){return _vm.$router.push(("/defect-cause-edit/" + (item.id)))}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)}),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogchangeapproval),callback:function ($$v) {_vm.dialogchangeapproval=$$v},expression:"dialogchangeapproval"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Change Approval User")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.approver_all_show,"label":"Old Approval","item-text":"name","item-value":"id","outlined":"","required":""},model:{value:(_vm.itemchangeapproval.oldapproval),callback:function ($$v) {_vm.$set(_vm.itemchangeapproval, "oldapproval", $$v)},expression:"itemchangeapproval.oldapproval"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.approver_active_show,"label":"New Approval","item-text":"name","item-value":"id","outlined":"","required":""},model:{value:(_vm.itemchangeapproval.newapproval),callback:function ($$v) {_vm.$set(_vm.itemchangeapproval, "newapproval", $$v)},expression:"itemchangeapproval.newapproval"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cancelchangeapproval()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.savechangeapproval()}}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }