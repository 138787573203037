import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const listTimecardByCompany = (company_id) => {
  return httpClient.get(server.TIMECARD + `/company/${company_id}`);
};

export const getTimecardWithUDefined = (tc_id, u_defined_module_id) => {
  return httpClient.get(`${server.TIMECARD}/${tc_id}/${u_defined_module_id}`)
}

export const getTimecardById = (tc_id) => {
  return httpClient.get(`${server.TIMECARD}/${tc_id}/log`)
}

export const getWorkOrderOption = () => {
  return httpClient.get(`${server.TIMECARD}/work_order/option`)
}

export const getOpnOrdOption = company_id => {
  return httpClient.get(`${server.TIMECARD}/opn-ord/company/${company_id}`)
}

export const postTimecard = timecard_id => {
  try {
    return httpClient.post(`${server.TIMECARD}/${timecard_id}/post`)
  } catch (error) {
    return {
      isError: true,
      error
    }
  }
}

export const getOpnOrd = opn_ord_id => httpClient.get(`${server.TIMECARD}/opn-ord/${opn_ord_id}`)

export const deleteTimecardLog = (time_card_id, time_card_log_id) => httpClient.delete(`${server.TIMECARD}/${time_card_id}/log/${time_card_log_id}`)

export const deleteTimecard = (time_card_id) => httpClient.delete(`${server.TIMECARD}/${time_card_id}`)


// export const getMachineById = (id, u_define_module_id) => {
//   return httpClient.get(server.MACHINE + `/${id}/${u_define_module_id}`);
// };

// export const getMachineWithWCById = (mch_id) => {
//   return httpClient.get(server.MACHINE + `/${mch_id}`)
// }


export const addTimecard = (data) => {
  return httpClient.post(server.TIMECARD + `/`, data);
};

export const upsertTimecardLog = (data) => {
  return httpClient.post(`${server.TIMECARD}/${data.time_card_id}/log`, data)
}

// export const updateMachine = (id, data) => {
//   return httpClient.put(server.MACHINE + `/${id}`, data);
// };

// export const deleteMachine = (id) => {
//   return httpClient.delete(server.MACHINE + `/${id}`);
// };

// export const listMachineShift = (machine_id) => {
//   return httpClient.get(server.MACHINE + `/${machine_id}/shift`)
// }

// export const addMachineShift = (machine_id, data) => {
//   return httpClient.post(server.MACHINE + `/${machine_id}/shift`, data)
// }

// export const deleteMachineShift = (machine_id, shift_id) => {
//   return httpClient.delete(server.MACHINE + `/${machine_id}/shift/${shift_id}`)
// }