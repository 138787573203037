var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-navigation-drawer',{attrs:{"app":"","permanent":"","dark":"","color":"#ffffff","width":"70px"}},[_c('router-link',{attrs:{"to":"/","exact":""}},[_c('div',{style:({
        backgroundColor: '#ffffff',
        color: '#ffffff',
        textAlign: '-webkit-center',
      })},[_c('v-img',{attrs:{"src":require("@/assets/MVibes_Logo1.jpg"),"color":"#ffffff","alt":"","height":"50px","width":"50px","contain":""}})],1)]),_c('v-list',[_c('v-list-item-group',{attrs:{"mandatory":"","active-class":"bg-active"},model:{value:(_vm.$store.state.modelmenu),callback:function ($$v) {_vm.$set(_vm.$store.state, "modelmenu", $$v)},expression:"$store.state.modelmenu"}},_vm._l((_vm.menus),function(item){return _c('v-menu',{key:item.title,attrs:{"prepend-icon":item.action,"bottom":"","offset-x":!item.route},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({style:({
              justifyContent: 'center',
            }),on:{"click":function($event){return _vm.onClickMenuItem(item)}}},'v-list-item',attrs,false),on),[_c('v-tooltip',{attrs:{"right":"","color":"#2a4bc1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({domProps:{"textContent":_vm._s(item.action)}},on)),(item.title == 'Approval' &&
                    _vm.$store.state.alertapprovalmenu != 0
                    )?_c('v-badge',{staticClass:"badgeapprover",attrs:{"color":"red","content":_vm.$store.state.alertapprovalmenu.toString()}}):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1)]}}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[(!item.route)?_c('v-list',_vm._l((item.items),function(subItem){return _c('v-list-item-content',{key:subItem.title,staticClass:"tile",on:{"click":function($event){return _vm.onClickMenuItem(subItem)}}},[_c('v-list-item-title',{staticClass:"ml-3"},[_vm._v(_vm._s(subItem.title))])],1)}),1):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }